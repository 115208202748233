import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  label: {
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(2),
  },
  tileContainer: {
    paddingBottom: 10,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(max-content, 240px))',
    gap: theme.spacing(1, 2),
  },
  tile: {
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0.5, 2, 0.5, 1),
    margin: 0,
    borderRadius: 10,
    border: `2px solid ${theme.palette.grey[300]}`,
    position: 'relative',
  },
  tileChecked: {
    borderColor: theme.palette.secondary.main,
  },
  optionLabel: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.h6.fontSize,
  },
  optionLabelChecked: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));
