import React from 'react';
import { Tab as MuiTab } from '@material-ui/core';
import useStyles from './styles';

const Tab = ({ ...props }) => {
  const classes = useStyles();

  return (
    <MuiTab
      classes={{
        root: classes.root,
      }}
      {...props}
    />
  );
};

export default Tab;
