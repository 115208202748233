import React from 'react';
import { Tabs as MuiTabs } from '@material-ui/core';
import useStyles from './styles';

const Tabs = ({ ...props }) => {
  const classes = useStyles();

  return (
    <MuiTabs
      classes={{
        root: classes.root,
        indicator: classes.indicator,
      }}
      {...props}
    />
  );
};

export default Tabs;
