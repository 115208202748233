import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

const styles = {
  base: {
    backgroundImage: 'url(images/general/gradient.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 34px',
    backgroundPosition: '0px -18px',
    overflow: 'auto',
    height: 32,
    marginTop: 16,
    display: 'flex',
    backgroundColor: 'transparent',
  },
};

const Shadow = ({ classes }) => <div className={classes.base} />;

Shadow.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Shadow);
