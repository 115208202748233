import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@utilitywarehouse/colour-system';

const style = (theme) => ({
  buttonLabel: {
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
    fontWeight: 600,
    fontSize: 16,
    justifyContent: 'space-between',
    color: colors.grey1000,
  },
  icon: {
    transition: 'transform 0.25s ease-out',
    color: theme.palette.secondary.main,
  },
  iconOpen: {
    transform: 'rotate(180deg)',
  },
  fullWidth: {
    width: '100%',
  },
});

export default makeStyles(style);
