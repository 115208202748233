import React, { useState, Children } from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import { IconButton } from '@material-ui/core';
import { NextIcon, PreviousIcon } from '@utilitywarehouse/partner-ui-icons';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import useStyles from './styles';

const Carousel = ({
  showIndicators = true,
  onSlideChanged = () => {},
  children,
  ...props
}) => {
  const classes = useStyles();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isNextSlideDisabled, setNextSlideDisabled] = useState(false);
  const [isPrevSlideDisabled, setPrevSlideDisabled] = useState(true);
  const count = Children.count(children);

  const onNextClick = () => {
    const nextSlide = currentSlide + 1;
    setCurrentSlide(nextSlide);
  };
  const onPreviousClick = () => {
    const prevSlide = currentSlide - 1;
    setCurrentSlide(prevSlide);
  };
  const updateCurrentSlide = ({
    item,
    isNextSlideDisabled,
    isPrevSlideDisabled,
  }) => {
    if (isNextSlideDisabled !== undefined)
      setNextSlideDisabled(isNextSlideDisabled);
    if (isPrevSlideDisabled !== undefined)
      setPrevSlideDisabled(isPrevSlideDisabled);

    onSlideChanged({ item, isNextSlideDisabled, isPrevSlideDisabled });
    setCurrentSlide(item);
  };

  return (
    <>
      <AliceCarousel
        disableDotsControls
        disableButtonsControls
        activeIndex={currentSlide}
        onSlideChanged={updateCurrentSlide}
        {...props}
      >
        {children}
      </AliceCarousel>
      {count > 1 && (
        <div className={classes.buttonContainer}>
          {showIndicators && (
            <ul className={classes.indicatorContainer}>
              {children?.map((_, index) => {
                const isSelected = index === currentSlide;
                return (
                  <li
                    className={clsx(
                      classes.indicator,
                      isSelected && classes.indicatorSelected
                    )}
                    onClick={() => updateCurrentSlide({ item: index })}
                    onKeyDown={() => updateCurrentSlide({ item: index })}
                    value={index}
                    key={index}
                    role="button"
                    tabIndex={0}
                    title={`slide ${index + 1}`}
                    aria-label={`slide ${index + 1}`}
                  />
                );
              })}
            </ul>
          )}
          <div>
            <IconButton
              disabled={isPrevSlideDisabled}
              onClick={() => onPreviousClick()}
              classes={{
                root: clsx(
                  classes.circleButton,
                  classes.left,
                  isPrevSlideDisabled && classes.disabled
                ),
              }}
            >
              <PreviousIcon />
            </IconButton>
            <IconButton
              disabled={isNextSlideDisabled}
              onClick={() => onNextClick()}
              classes={{
                root: clsx(
                  classes.circleButton,
                  isNextSlideDisabled && classes.disabled
                ),
              }}
            >
              <NextIcon />
            </IconButton>
          </div>
        </div>
      )}
    </>
  );
};

Carousel.propTypes = {
  showIndicators: PropTypes.bool,
  onSlideChanged: PropTypes.func,
  children: PropTypes.node,
};

export default Carousel;
