import React from 'react';
import PropTypes from 'prop-types';
import csx from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import styles from './styles';
import './styles.css';

class SelectField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isFocused: false,
    };
  }

  render() {
    const {
      classes,
      classNames,
      testId,
      id,
      fullWidth,
      label,
      value,
      margin,
      errorText,
      onChange,
      options,
      onBlur,
      disabled,
      noResultsText,
      tabIndex,
      required,
      ...otherProps
    } = this.props;
    const labelClass =
      this.state.isOpen || value
        ? this.state.isFocused
          ? classes.labelOpenFocused
          : classes.labelOpen
        : classes.label;

    return (
      <div
        className={csx({
          [classes.container]: true,
          [classes.withMargin]: margin,
          [classNames.root]: !!classNames.root,
        })}
      >
        <label htmlFor={id} className={csx(labelClass, classes.label)}>
          {label}
        </label>
        <Select
          backspaceRemoves
          clearable
          closeOnSelect
          disabled={disabled}
          fullWidth={fullWidth}
          id={id}
          labelKey="text"
          noResultsText={noResultsText}
          onBlur={() => {
            onBlur();
            this.setState({ isFocused: false });
          }}
          onChange={onChange}
          onClose={() => this.setState({ isOpen: false })}
          onFocus={() => this.setState({ isFocused: true })}
          onOpen={() => this.setState({ isOpen: true })}
          placeholder=""
          options={options}
          required={required}
          resetValue={{ text: undefined, val: undefined }}
          searchPromptText={label}
          tabIndex={tabIndex}
          value={value}
          valueKey="val"
          {...otherProps}
        />
        <span className={classes.errorText}>{errorText}</span>
      </div>
    );
  }
}

SelectField.defaultProps = {
  classes: {},
  classNames: {},
  testId: '',
  id: 'SelectField',
  fullWidth: false,
  label: '',
  margin: true,
  value: '',
  errorText: '',
  style: {},
  onBlur: () => {},
  disabled: false,
  noResultsText: 'no results',
  tabIndex: 0,
  required: false,
};

SelectField.propTypes = {
  classes: PropTypes.object.isRequired,
  classNames: PropTypes.object.isRequired,
  margin: PropTypes.bool.isRequired,
  testId: PropTypes.string,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errorText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      val: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  noResultsText: PropTypes.string,
  tabIndex: PropTypes.number,
  required: PropTypes.bool,
};

export default withStyles(styles)(SelectField);
