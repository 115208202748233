import React from 'react';
import clsx from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Deprecated } from 'app/lib/propTypes';
import styles from './styles';

const ErrorMessage = ({ messageStyle, message, classes, className }) => (
  <Typography
    component="p"
    classes={{
      root: clsx(classes.errorText, className),
    }}
    style={messageStyle}
  >
    {message}
  </Typography>
);

ErrorMessage.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
  messageStyle: Deprecated(PropTypes.object),
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ErrorMessage);
