import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import useStyles from './styles';

export const TableLegend = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.legendContainer}>{children}</div>;
};

TableLegend.propTypes = {
  children: PropTypes.node.isRequired,
};

export const TableLegendCell = ({ children }) => {
  const classes = useStyles();

  return <div className={clsx(classes.legendCell)}>{children}</div>;
};

TableLegendCell.propTypes = {
  children: PropTypes.node.isRequired,
};

export const TableColumnContainer = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.columnContainer}>{children}</div>;
};

TableColumnContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export const TableColumn = ({ children, label }) => {
  const classes = useStyles();

  return (
    <div className={classes.column}>
      <TableCell>{label}</TableCell>
      {children}
    </div>
  );
};

TableColumn.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.node.isRequired,
};

export const TableCell = ({ children, fluid = false }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.cell, { [classes.fluid]: fluid })}>
      {children}
    </div>
  );
};

TableCell.propTypes = {
  children: PropTypes.node.isRequired,
  fluid: PropTypes.bool,
};

export const Table = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.container}>{children}</div>;
};

Table.propTypes = {
  children: PropTypes.node.isRequired,
};
