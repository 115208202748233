import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';

import {
  makeStyles,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  ExpansionPanel,
  Typography,
} from '@material-ui/core';

import {
  Done as DoneIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import { fade } from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles(({ typography, palette, spacing }) => ({
  expansionPanelRoot: {
    borderRadius: spacing(2),
    '&:first-of-type': {
      borderRadius: spacing(2),
    },
    '&:last-of-type': {
      borderRadius: spacing(2),
    },

    '&.Mui-expanded:first-child': {
      marginTop: spacing(2),
    },
  },
  title: {
    ...typography.body1,
    fontWeight: 600,
  },
  detailsConatiner: {
    display: 'flex',
    flexDirection: 'column',

    '& > *:last-child': {
      borderBottom: 'unset',
      paddingBottom: 0,
    },
  },
  details: {
    borderBottom: `2px solid ${fade(palette.primary.main, 0.2)}`,
    display: 'flex',
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
  },
  icon: {
    marginRight: spacing(2),
  },
}));

const ContentListPanel = ({ title, items, Icon = DoneIcon }) => {
  const classes = useStyles();

  return (
    <ExpansionPanel
      classes={{
        root: classes.expansionPanelRoot,
      }}
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon color="secondary" />}>
        <Typography color="primary" className={classes.title}>
          {title}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.detailsConatiner}>
        {map(items, (text) => (
          <div key={text} className={classes.details}>
            <Icon color="primary" className={classes.icon} />
            <Typography>{text}</Typography>
          </div>
        ))}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

ContentListPanel.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  Icon: PropTypes.element,
};

export default ContentListPanel;
