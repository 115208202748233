import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { createTheme } from '@utilitywarehouse/partner-ui-mui-theme';

const useStyles = makeStyles(
  ({ spacing, breakpoints, typography, palette }) => ({
    container: {
      marginBottom: spacing(3),
    },
    root: {
      display: 'flex',
      paddingBottom: spacing(2),
      rowGap: `${spacing(1)}px`,
      alignItems: 'center',
      justifyContent: 'space-around',
      flexDirection: 'column',
      borderRadius: spacing(1),
      [breakpoints.up('sm')]: {
        flexDirection: 'row',
        maxHeight: 110,
        padding: spacing(3),
        rowGap: 0,
        columnGap: spacing(2),
      },
    },
    imageWrapper: {
      display: 'flex',
      width: '100%',
      marginLeft: spacing(-3),
      [breakpoints.up('sm')]: {
        width: 'auto',
      },
    },
    image: {
      height: 'auto',
      maxWidth: 420,
      objectFit: 'contain',
    },
    textContentWrapper: {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'end',
      flexDirection: 'column',
      flexBasis: 'content',
      flexGrow: 1,
      rowGap: `${spacing(1)}px`,
      padding: spacing(0, 1),
      [breakpoints.up('sm')]: {
        padding: 0,
      },
    },
    textContent: {
      display: 'inline-block',
      fontSize: 18,
      lineHeight: 1.15,
      fontWeight: typography.fontWeightMedium,
      textAlign: 'center',
      [breakpoints.up('sm')]: {
        textAlign: 'left',
      },
    },
    highlight: {
      color: palette.services.broadband,
    },
  })
);

const darkTheme = createMuiTheme(createTheme('dark'));

export { useStyles, darkTheme };
