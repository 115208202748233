import React from 'react';
import PropTypes from 'prop-types';
import RestrictedButton from 'redux/modules/RemoteSession/v2/RestrictedButton';
import { useHistory } from 'react-router';
import { isPathSaveEnabled } from 'app/redux/modules/Persistence/filters';

const Save = ({ visible, openGlobalDialog, applicationId }) => {
  const history = useHistory();
  if (!visible || !isPathSaveEnabled(history.location.pathname)) return null;

  return (
    <RestrictedButton
      dataTestId={'ContinueLaterButton'}
      onClick={() => {
        openGlobalDialog(
          'Continue later',
          `Continue this application later by using the following code on the entry form: ${applicationId}`
        );
      }}
      variant="outlined"
      label={'Continue later'}
    />
  );
};

Save.propTypes = {
  visible: PropTypes.bool,
  openGlobalDialog: PropTypes.func,
  applicationId: PropTypes.string,
};

export default Save;
