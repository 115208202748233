import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import clsx from 'classnames';
import { Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { fade } from '@material-ui/core/styles/colorManipulator';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FeatureFlagsApi } from 'redux/modules/FeatureFlags/api';
import { FLAGS } from 'redux/modules/FeatureFlags/constants';

const getFlag = FeatureFlagsApi.getFlagSelector(FLAGS.ENERGY_EPG_ANNOUNCEMENT);

const useStyles = makeStyles((theme) => ({
  alertBanner: {
    maxWidth: 800,
    padding: theme.spacing(2, 2, 2, 2),
    backgroundColor: fade(theme.palette.warning.main, 0.1),
    borderWidth: 2,
    color: theme.palette.text.primary,

    '& .MuiAlert-icon': {
      color: theme.palette.text.primary,
    },
  },
}));

const EPGAlert = ({ classes = {} }) => {
  const internalClasses = useStyles();
  const flagEnabled = useSelector(getFlag);
  if (!flagEnabled) return null;
  return (
    <Alert
      variant="outlined"
      severity="warning"
      className={clsx(internalClasses.alertBanner, classes.root)}
    >
      <Typography variant="body2">
        The prices below are based on the new tariff rates that will take effect
        on 1 July 2024. If your energy service goes live before then, you will
        be charged at the current, slightly higher rate (which is still lower
        than the current price cap) until the new rates take effect in July.
      </Typography>
    </Alert>
  );
};

EPGAlert.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

export default EPGAlert;
