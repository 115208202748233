import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { DropdownIcon } from '@utilitywarehouse/partner-ui-icons';
import clsx from 'classnames';
import { Typography, MenuList, MenuItem, ButtonBase } from '@material-ui/core';
import DropdownMenu from 'components/modules/Shared/DropdownMenu';
import useStyles from './styles';

function link(href, newTab = true) {
  const target = newTab ? '_blank' : '_self';

  const windowFeatures = [];
  if (newTab) {
    windowFeatures.push('noreferrer');
    windowFeatures.push('noopener');
  }

  return window.open(href, target, windowFeatures.join(', '));
}

const Profile = ({
  logout,
  isRemoteSession,
  isPartnerSession,
  supportingPartnerId,
  displayName,
}) => {
  const classes = useStyles();
  const buttonRef = useRef();
  const [menuOpen, setMenuOpen] = useState(false);
  const handleLogout = React.useCallback(() => {
    logout();
    setMenuOpen(false);
  }, [logout, setMenuOpen]);

  return (
    <DropdownMenu open={menuOpen} onClose={() => setMenuOpen(false)}>
      <ButtonBase
        disabled={!isPartnerSession}
        onClick={(e) => {
          e.stopPropagation();
          setMenuOpen(!menuOpen);
        }}
        ref={buttonRef}
      >
        <Typography className={classes.base} component="div" variant="body1">
          {displayName && (
            <div className={classes.profile}>
              {displayName && (
                <>
                  <Typography
                    className={classes.profileDisplayName}
                    component="span"
                  >
                    {displayName}
                  </Typography>
                </>
              )}
              {supportingPartnerId && (
                <Typography className={classes.supportingId} component="span">
                  {supportingPartnerId}
                </Typography>
              )}
              {isPartnerSession && (
                <DropdownIcon
                  color="secondary"
                  className={clsx(classes.profileDropdownIcon, {
                    [classes.profileDropdownIconOpen]: menuOpen,
                  })}
                />
              )}
            </div>
          )}
        </Typography>
      </ButtonBase>
      <div className={classes.profileDropdown}>
        <MenuList>
          {displayName && isPartnerSession && (
            <MenuItem onClick={() => link('/partner/portal')}>
              My account
            </MenuItem>
          )}
          <MenuItem onClick={() => link('/contact')}>Help</MenuItem>
          {displayName && !isRemoteSession && (
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          )}
        </MenuList>
      </div>
    </DropdownMenu>
  );
};

Profile.propTypes = {
  logout: PropTypes.func,
  displayName: PropTypes.string,
  supportingPartnerId: PropTypes.string,
  isRemoteSession: PropTypes.bool,
  isPartnerSession: PropTypes.bool,
};

export default Profile;
