import { makeStyles } from '@material-ui/core';
import {
  getBreakpoints,
  getSpacing,
  getCommonPaletteColor,
} from 'app/lib/theme';

export default (theme) => {
  const white = getCommonPaletteColor('white')(theme);
  const spacing = getSpacing(theme);
  const breakpoints = getBreakpoints(theme);

  return {
    alert: {
      margin: spacing(1, 0, 4, 0),
    },
    errorAlert: {
      margin: spacing(-2, 0, 2, 0),
    },
    buttonContainer: {
      display: 'flex',
      padding: spacing(0, 0, 3, 0),
      justifyContent: 'space-between',
      position: 'relative',
      flexDirection: 'column-reverse',
      [breakpoints.up('sm')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      '& > *': {
        marginTop: spacing(2),
      },
    },
    buttonContainerStickyNav: {
      display: 'flex',
      padding: spacing(0, 0, 2, 0),
      justifyContent: 'space-between',
      position: 'relative',
      [breakpoints.up('sm')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      '& > *': {
        marginTop: spacing(2),
      },
    },
    nextButtonContainer: {
      display: 'flex',
      gap: spacing(0, 1),
      flexDirection: 'column-reverse',
      '& > *:last-child': {
        marginBottom: spacing(2),
      },
      [breakpoints.up('sm')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        '& > *': {
          marginLeft: spacing(2),
        },
        '& > *:last-child': {
          marginBottom: 0,
        },
      },
    },
    nextButtonContainerStickyNav: {
      display: 'flex',
      gap: spacing(0, 1),
      [breakpoints.up('sm')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        '& > *': {
          marginLeft: spacing(2),
        },
        '& > *:last-child': {
          marginBottom: 0,
        },
      },
    },
    informationContainer: {
      margin: '0 auto',
    },
    whiteSectionCentered: {
      backgroundColor: white,
      filter: 'drop-shadow(0px -2px 3px rgba(0, 0, 0, 0.08))',
    },
    bundlesContainer: {
      paddingBottom: spacing(4),
    },
    sticky: {
      position: 'sticky',
      bottom: 0,
    },
    marginTop: {
      marginTop: spacing(6),
    },
    marginTopZero: {
      marginTop: spacing(0),
    },
  };
};

export const useNextButtonStyles = makeStyles((theme) => ({
  tooltip: {
    fontSize: theme.typography.caption.fontSize,
  },
}));
