import PropTypes from 'prop-types';
import React from 'react';
import VMasker from 'vanilla-masker';
import TextField from '../TextField';
import CustomerUITextField from '../../CustomerUI/TextField';

const MASK_INTEGER = 'int';

class MaskedTextField extends React.Component {
  constructor(props) {
    super(props);
    if (!props.noState) {
      // for legacy payment form inputs
      this.state = {
        value: props.defaultValue,
      };
    }
  }

  onChange(mask, e) {
    let value =
      MASK_INTEGER === mask
        ? VMasker.toNumber(e.target.value)
        : VMasker.toPattern(e.target.value, mask);

    if (this.props.autofillSymbols && MASK_INTEGER !== mask) {
      const valueLength = e.target.value.length;
      if (
        valueLength < mask.length &&
        this.props.autofillSymbols.includes(mask[valueLength])
      ) {
        value = `${value}${mask[valueLength]}`;
      }
    }

    if (!this.props.noState) this.setState({ value });

    if (this.props.onChange) {
      // MaterialUI has <event>, <value> as arguments
      // for onChange events by default. Use this prop to conform
      // where appropriate
      // TODO: update existing implementations to conform to standard and remove this eventually
      if (this.props.standardChangeArgs) {
        this.props.onChange(e, value);
      } else {
        this.props.onChange(value, e);
      }
    }
  }

  render() {
    const {
      mask,
      noState,
      useCustomerUI,
      standardChangeArgs,
      ...other
    } = this.props;
    if (!noState) {
      delete other.defaultValue; // for legacy payment form inputs
      other.value = this.state.value;
    }
    if (useCustomerUI) {
      return (
        <CustomerUITextField
          {...other}
          onChange={this.onChange.bind(this, mask)}
        />
      );
    }
    return <TextField {...other} onChange={this.onChange.bind(this, mask)} />;
  }
}

MaskedTextField.defaultProps = {
  standardChangeArgs: false,
};

MaskedTextField.propTypes = {
  noState: PropTypes.bool,
  useCustomerUI: PropTypes.bool,
  onChange: PropTypes.func,
  defaultValue: PropTypes.any,
  mask: PropTypes.string,
  autofillSymbols: PropTypes.arrayOf(PropTypes.string),
  standardChangeArgs: PropTypes.bool,
};

export default MaskedTextField;
