import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  base: {
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  profile: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(0, 0, 0.5),
    width: '100%',
    color: theme.palette.text.primary,
  },
  profileLight: {
    color: theme.palette.text.primary,
  },
  profileDisplayName: {
    color: theme.palette.text.primary,
    fontSize: '1.8rem',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  profileLink: {
    color: theme.palette.text.primary,
    fontSize: '1.8rem',
    '&:before': {
      content: '"|"',
      lineHeight: 1,
      margin: theme.spacing(0, 1),
    },
    '&:first-child': {
      '&:before': {
        display: 'none',
      },
    },
  },
  profileDropdown: {
    minWidth: 200,
    background: theme.palette.common.white,
    border: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: theme.spacing(1),
  },
  profileDropdownIcon: {
    marginLeft: theme.spacing(1),
    transition: 'transform 0.25s ease-out',
  },
  profileDropdownIconOpen: {
    transform: 'rotate(180deg)',
  },
  supportingId: {
    flexGrow: 1,
    fontSize: '1.8rem',
    whiteSpace: 'nowrap',
    color: theme.palette.text.primary,
    opacity: 0.5,
    '&:before': {
      content: '"|"',
      lineHeight: 1,
      margin: theme.spacing(0, 1),
    },
  },
});

export default makeStyles(styles);
