import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { EligibilityServiceApi } from 'redux/modules/Eligibility/api';
import { ServiceSelectionApi } from 'redux/modules/ServiceSelection/api';
import { OrderSummaryApi } from 'app/redux/modules/OrderSummary/api';
import BoostBanner from './Boost';

const BoostContainer = ({ classes = {} }) => {
  const isEligible = useSelector(EligibilityServiceApi.isEligibleForBoost);
  const serviceCount = useSelector(ServiceSelectionApi.getSelectedServices)
    ?.length;
  const lead = useSelector(OrderSummaryApi.getApplicationLead);

  return (
    <BoostBanner
      isEligible={isEligible}
      serviceCount={serviceCount}
      classes={classes}
      lead={lead}
    />
  );
};

BoostContainer.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

export default BoostContainer;
