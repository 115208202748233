import React from 'react';
import PropTypes from 'prop-types';
import { Element, scroller } from 'react-scroll';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const JumpTo = (props) => {
  const location = useLocation();
  const { id, autoScroll, children } = props;

  useEffect(() => {
    if (autoScroll) {
      scroller.scrollTo(id);
    }
  }, [location.pathname]);

  return <Element name={id}>{children}</Element>;
};

JumpTo.propTypes = {
  autoScroll: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
};

JumpTo.defaultProps = {
  autoScroll: true,
};

export default JumpTo;
