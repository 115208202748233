import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import CopyButton from 'components/modules/Shared/CopyButton';
import useStyles from './styles';

const Share = ({ id, label, value, onCopy }) => {
  const classes = useStyles();

  return (
    <div className={classes.container} data-test-id={id}>
      <div className={classes.labelWrapper}>
        <Typography color="primary" className={classes.label}>
          {value}
        </Typography>
      </div>
      <CopyButton
        label={label}
        value={value}
        onCopy={onCopy}
        buttonClasses={{ containedSecondary: classes.button }}
      />
    </div>
  );
};

Share.defaultProps = {
  onCopy: () => {},
  label: 'Copy',
  id: null,
};

Share.propTypes = {
  value: PropTypes.string.isRequired,
  onCopy: PropTypes.func,
  label: PropTypes.string,
  id: PropTypes.string,
};

export default Share;
