import React from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/moment';
// TODO @material-ui/pickers is depracated, replace when upgrading to mui@5
//      https://mui.com/material-ui/guides/pickers-migration/
import {
  KeyboardDatePicker as MuiDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { CalendarIcon } from '@utilitywarehouse/partner-ui-icons';
import TextField from 'modules/CustomerUI/TextField';

const DatePicker = ({ value, onChange, ...props }) => {
  // value must be `null` for the input to be considered "empty"
  // anything else causes `undefined` to be sent to the formatter (rifm)
  // which will cause a crash
  // https://github.com/mui/material-ui-pickers/blob/e875729b3492a4aedebec9f14589accc83475241/lib/src/_helpers/text-field-helper.ts#L16
  const normalizedValue = value || null;

  const getCalDate = (date) => {
    if (!date) return null;
    return `${date.format('YYYY-MM-DD')}T00:00:00.000Z`;
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <MuiDatePicker
        value={normalizedValue}
        format="DD/MM/yyyy"
        onChange={(d) => onChange(new Event('change'), getCalDate(d))}
        TextFieldComponent={TextField}
        inputVariant="filled"
        keyboardIcon={<CalendarIcon color="secondary" />}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
};

DatePicker.propTypes = {
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  onChange: PropTypes.func.isRequired,
};

export default DatePicker;
