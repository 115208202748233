import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

const GlobalDialog = ({
  open = false,
  title,
  message,
  actions,
  handleClose,
}) => (
  <Dialog open={open}>
    <DialogTitle>{title || 'Error'}</DialogTitle>
    <DialogContent>
      <Typography>{message || ''}</Typography>
    </DialogContent>
    <DialogActions>
      {actions || (
        <Button key="handleCloseBtn" onClick={handleClose && handleClose}>
          OK
        </Button>
      )}
    </DialogActions>
  </Dialog>
);

GlobalDialog.propTypes = {
  actions: PropTypes.bool,
  handleClose: PropTypes.func,
  message: PropTypes.string,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

export default GlobalDialog;
