import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import useStyles from './styles';

const ProgressTracker = ({
  steps,
  currentStep,
  size = 50,
  active,
  Icon = <div />,
  dark = false,
  complete = false,
}) => {
  const classes = useStyles();

  const padding = size * 0.05;
  const radius = (size - padding) / 2;
  const circumfrance = 2 * Math.PI * radius;
  const section = circumfrance / steps - padding * 2;

  // angle from arc:
  // A/πd × 360
  // A = arc length
  // d = diameter
  const offsetRotation = (padding / (Math.PI * (radius * 2))) * 360 - 90;

  return (
    <div className={classes.wrapper} style={{ width: size, height: size }}>
      <svg height={size} width={size} transform={`rotate(${offsetRotation})`}>
        {active &&
          !complete &&
          [...Array(steps).keys()].map((step, i) => (
            <circle
              key={i}
              className={clsx(classes.step, {
                [classes.stepDark]: dark,
                [classes.stepComplete]: i + 1 < currentStep,
              })}
              cx="50%"
              cy="50%"
              r={radius}
              strokeWidth={padding}
              strokeDasharray={
                steps > 1 ? `${section}, ${circumfrance}` : 'false'
              }
              transform={`rotate(${(360 / steps) * i})`}
            />
          ))}
        {complete && (
          <circle
            className={clsx(classes.step, {
              [classes.stepComplete]: complete,
            })}
            cx="50%"
            cy="50%"
            r={radius}
            strokeWidth={padding}
          />
        )}
      </svg>
      <div
        className={clsx(classes.content, {
          [classes.contentDark]: dark,
          [classes.contentComplete]: complete,
        })}
      >
        {Icon}
      </div>
    </div>
  );
};

ProgressTracker.propTypes = {
  steps: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  size: PropTypes.number,
  active: PropTypes.bool,
  Icon: PropTypes.node,
  dark: PropTypes.bool,
  complete: PropTypes.bool,
};

export default ProgressTracker;
