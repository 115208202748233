import {
  getErrorPaletteColor,
  getTextPaletteColor,
  getSecondaryPaletteColor,
  getSpacing,
} from 'app/lib/theme';

const labelOpen = {
  color: 'grey',
  pointerEvents: 'none',
  transform: 'scale(0.75) translate(0, -16px)',
  transformOrigin: 'left top 0px',
  transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
  userSelect: 'none',
  zIndex: 3,
};

export default (theme) => {
  const activeColor = getSecondaryPaletteColor('main')(theme);
  const errorColor = getErrorPaletteColor('main')(theme);
  const textColor = getTextPaletteColor('main')(theme);
  const spacing = getSpacing(theme);

  return {
    container: {
      minWidth: 100,
      position: 'relative',
    },
    errorText: {
      bottom: -18,
      color: errorColor,
      position: 'absolute',
    },
    label: {
      color: textColor,
      fontSize: '1.6rem',
      left: 14,
      overflow: 'hidden',
      pointerEvents: 'none',
      position: 'absolute',
      top: 21,
      transform: 'scale(1) translate(0px, 0px)',
      transformOrigin: 'left top 0px',
      transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
      userSelect: 'none',
      zIndex: 3,
    },
    labelOpen,
    labelOpenFocused: {
      ...labelOpen,
      color: activeColor,
    },
    withMargin: {
      marginBottom: spacing(1),
      marginTop: spacing(2),
    },
  };
};
