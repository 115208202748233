import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

const StartAgainDialog = ({ open, onStartAgain, onClose }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Do you want to start again?</DialogTitle>
    <DialogContent>
      <Typography>
        You'll be logged out and redirected to the home page
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onStartAgain}>Start again</Button>
      <Button variant="outlined" onClick={onClose}>
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

StartAgainDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onStartAgain: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default StartAgainDialog;
