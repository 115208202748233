import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty } from 'lodash/fp';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import {
  InfoOutlined,
  FlagPointIcon,
} from '@utilitywarehouse/partner-ui-icons';

import {
  getSpacing,
  getPrimaryPaletteColor,
  getSuccessPaletteColor,
  getServicePaletteColor,
  getActionPaletteColor,
} from 'app/lib/theme';

const getPayloadType = (payload) =>
  !isEmpty(payload)
    ? Object.keys(payload).find((key) => !!payload[key] && key !== 'ticks') ||
      null
    : null;

const styles = (theme) => {
  const dark = getPrimaryPaletteColor('dark')(theme);
  const success = getSuccessPaletteColor('main')(theme);
  const mobile = getServicePaletteColor('mobile')(theme);
  const disabled = getActionPaletteColor('disabled')(theme);
  const spacing = getSpacing(theme);

  return {
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: spacing(2),
    },
    contentHolder: {
      display: 'flex',
    },
    featureDesc: {
      color: dark,
      marginLeft: spacing(2),
    },
    disabled: {
      color: disabled,
    },
    enabled: {
      color: success,
    },
    gold: {
      color: mobile,
    },
    flatPointIcon: {
      marginRight: spacing(1),
      marginTop: 3,
    },
    infoIcon: {
      cursor: 'pointer',
      fontSize: 20,
      marginTop: spacing(0.5),
    },
  };
};

const BundleBenefit = ({
  benefitId,
  title,
  isGold,
  onOpenInfo,
  payload,
  disabled,
  children,
  titleVariant = 'subtitle1',
  bundleBenefitClasses = {},
  classes,
  bullet = true,
}) => (
  <div className={classNames(classes.root, bundleBenefitClasses.root)}>
    <div
      className={classNames(
        classes.contentHolder,
        bundleBenefitClasses.contentHolder
      )}
    >
      {bullet && (
        <FlagPointIcon
          className={classNames(
            classes.flatPointIcon,
            isGold && !disabled && classes.gold,
            disabled ? classes.disabled : classes.enabled
          )}
        />
      )}
      <Typography
        variant={titleVariant}
        className={classNames(
          classes.featureDesc,
          bundleBenefitClasses.title,
          disabled && classes.disabled
        )}
      >
        {title || children}
      </Typography>
    </div>
    {!!getPayloadType(payload) && (
      <InfoOutlined
        className={classes.infoIcon}
        onClick={() => onOpenInfo(benefitId)}
      />
    )}
  </div>
);

BundleBenefit.propTypes = {
  benefitId: PropTypes.string,
  title: PropTypes.string,
  isGold: PropTypes.bool,
  onOpenInfo: PropTypes.func,
  payload: PropTypes.object,
  disabled: PropTypes.bool,
  titleVariant: PropTypes.string,
  children: PropTypes.node,
  bundleBenefitClasses: PropTypes.shape({
    contentHolder: PropTypes.string,
    title: PropTypes.string,
  }),
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BundleBenefit);
