import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Collapse } from '@material-ui/core';

import useStyles from './styles';
import ContentDropdownIcon from 'modules/Shared/ContentDropdown/ContentDropdownIcon';

const ContentDropdown = ({
  open,
  label,
  onOpen,
  children,
  fullWidth = false,
  classes = {},
}) => {
  const internalClasses = useStyles();

  return (
    <>
      <Button
        variant="text"
        fullWidth={fullWidth}
        endIcon={<ContentDropdownIcon open={open} />}
        onClick={onOpen}
        classes={{
          label: classNames(internalClasses.buttonLabel, classes.label),
          root: classes.root,
        }}
      >
        {label}
      </Button>
      <Collapse
        classes={{
          root: classNames(classes.collapse, {
            [internalClasses.fullWidth]: fullWidth,
          }),
        }}
        in={open}
      >
        {children}
      </Collapse>
    </>
  );
};

ContentDropdown.propTypes = {
  onOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  fullWidth: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
};

export default ContentDropdown;
