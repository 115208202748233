const line = {
  width: '100%',
  display: 'flex',
  marginTop: 15,
  marginBottom: 15,
};

export default {
  solid: {
    ...line,
    borderBottom: '1px solid #ddd',
  },
  dotted: {
    ...line,
    borderBottom: '1px dotted #ddd',
  },
  dashed: {
    ...line,
    borderBottom: '1px dashed #ddd',
  },
};
