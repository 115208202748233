import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { images } from '@utilitywarehouse/partner-ui-assets';

const useStyles = makeStyles((theme) => ({
  logo: {
    background: 'url("images/logo-simple.svg") no-repeat',
    backgroundSize: 'contain',
    cursor: 'pointer',
    flexShrink: 0,
    height: 30,
    marginRight: theme.spacing(3.5),
    outline: 'none',
    width: 64,
    [theme.breakpoints.up('sm')]: {
      height: 38,
      width: 204,
    },
  },
  logoLight: {
    [theme.breakpoints.up('sm')]: {
      background: `url("${images.logos.desktop.light}") no-repeat`,
    },
  },
  logoDark: {
    [theme.breakpoints.up('sm')]: {
      background: `url("${images.logos.desktop.dark}") no-repeat`,
    },
  },
  logoDisabled: {
    cursor: 'auto',
  },
}));

const Logo = ({
  disabled = false,
  theme = 'default',
  variant = 'small',
  onClick,
}) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.logo, {
        [classes.logoDisabled]: disabled,
        [classes.logoLight]: variant === 'large' && theme === 'light',
        [classes.logoDark]: variant === 'large' && theme === 'dark',
      })}
      onClick={() => {
        !disabled && onClick();
      }}
      role={!disabled ? 'link' : 'logo'}
      title="Utility Warehouse"
    />
  );
};

Logo.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  theme: PropTypes.oneOf(['light', 'dark', 'default']),
  variant: PropTypes.oneOf(['large', 'small']),
};

export default Logo;
