import React from 'react';
import Radium from 'radium';

/**
 * @class Class
 * @prop {width} add numeric width
 * @prop {height} add numeric width
 */
class Space extends React.Component {
  static defaultProps = {
    width: '0px',
    height: '0px',
  };

  render() {
    const { width, height } = this.props;

    const styles = {
      spacer: {
        width: [width],
        height: [height],
      },
    };

    return <div style={styles.spacer} />;
  }
}

export default Radium(Space);
