import React, { useRef, useState } from 'react';
import { IconButton, Typography } from '@material-ui/core';
import makeUseStyles from './styles';
import PropTypes from 'prop-types';

const ExpandableInfoLabel = ({ title, info }) => {
  const [displayInfo, setDisplayInfo] = useState(false);
  const iconRef = useRef();
  const containerRef = useRef();

  const offset =
    iconRef.current && containerRef.current
      ? iconRef.current?.offsetLeft - containerRef.current?.offsetLeft
      : undefined;

  const classes = makeUseStyles(offset)();
  return (
    <div className={classes.container} ref={containerRef}>
      <div className={classes.titleContainer}>
        <Typography className={classes.title}>{title}</Typography>
        <IconButton
          classes={{ root: classes.iconButton }}
          onClick={() => setDisplayInfo(!displayInfo)}
          ref={iconRef}
        >
          {displayInfo ? (
            <img
              alt="close"
              src="images/icons/close.svg"
              className={classes.icon}
            />
          ) : (
            <img
              alt="more information"
              src="images/icons/info.svg"
              className={classes.icon}
            />
          )}
        </IconButton>
      </div>
      {displayInfo && (
        <div className={classes.infoContainer}>
          <Typography>{info}</Typography>
        </div>
      )}
    </div>
  );
};

ExpandableInfoLabel.propTypes = {
  title: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
};

export default ExpandableInfoLabel;
