import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { formatPrice } from 'redux/utils/formatter';

const styles = (theme) => ({
  price: {
    display: 'inline-flex',
    alignItems: 'baseline',
    justifyContent: 'flex-start',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
    },
  },
  priceInterval: {
    whiteSpace: 'nowrap',
  },
  priceDigit: {
    fontSize: 42,
  },
});

const Price = ({ price, inheritColor = false }) => {
  const classes = makeStyles(styles)();

  return (
    <div className={classes.price}>
      <Typography
        className={classes.priceDigit}
        variant="h2"
        color={inheritColor ? '' : 'primary'}
      >
        {formatPrice(price)}
      </Typography>{' '}
      <Typography
        variant="subtitle1"
        color={inheritColor ? '' : 'primary'}
        className={classes.priceInterval}
      >
        / month
      </Typography>
    </div>
  );
};

Price.propTypes = {
  price: PropTypes.shape({
    value: PropTypes.number.isRequired,
    exponent: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
};

export default Price;
