import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

import { sanitiseText } from 'redux/utils/sanitise';

const NewTextField = ({
  id,
  dataTestId,
  dataPayment,
  onChange,
  value,
  label,
  type = 'text',
  fullWidth = false,
  disabled = false,
  placeholder,
  error,
  errorMessage,
  variant = 'filled',
  helperText = '',
  margin,
  required,
  textFieldClasses = {},
  ...textFieldProps
}) => {
  return (
    <FormControl
      error={error}
      fullWidth={fullWidth}
      margin={margin || 'normal'}
      classes={{ root: textFieldClasses.form }}
    >
      <TextField
        required={required || false}
        label={label}
        id={id || dataTestId}
        data-test-id={dataTestId}
        error={!disabled ? error : null}
        type={type}
        variant={variant}
        placeholder={placeholder}
        disabled={disabled}
        value={value || ''}
        onChange={(event) => onChange(event, sanitiseText(event.target.value))}
        classes={{
          root: textFieldClasses.input,
          label: textFieldClasses.label,
          input: textFieldClasses.input,
        }}
        inputProps={{
          'data-payment': dataPayment || null,
          'data-test-id': dataTestId,
        }}
        helperText={error && !disabled ? errorMessage : helperText}
        {...textFieldProps}
      />
    </FormControl>
  );
};

NewTextField.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  dataPayment: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  error: PropTypes.bool,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  helperText: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dataTestId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  margin: PropTypes.oneOf(['none', 'dense', 'normal']),
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
  textFieldClasses: PropTypes.shape({
    input: PropTypes.string,
    label: PropTypes.sring,
    helper: PropTypes.string,
    form: PropTypes.string,
  }),
};

export default NewTextField;
