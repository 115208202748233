import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import Spotlight from './spotlight.svg';
import styles from './styles';

export const IllustrationSpotlight = ({
  borderPosition,
  children,
  classes,
  ISClasses = {},
}) => (
  <div className={classNames(classes.base, ISClasses.base && ISClasses.base)}>
    <div className={classes.background} />
    <Spotlight
      className={classNames(classes.spotlight, classes[borderPosition])}
    />
    <div className={classes.image}>{children}</div>
  </div>
);

IllustrationSpotlight.propTypes = {
  borderPosition: PropTypes.oneOf([
    'bottomLeft',
    'bottomRight',
    'topLeft',
    'topRight',
  ]).isRequired,
  children: PropTypes.node.isRequired,
  ISClasses: PropTypes.shape({
    base: PropTypes.string,
  }),
  classes: PropTypes.object.isRequired,
};

IllustrationSpotlight.defaultProps = {
  borderPosition: 'topRight',
};

export default withStyles(styles)(IllustrationSpotlight);
