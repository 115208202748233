import gstyles from 'constants/styles';
import colors from 'constants/colors';
import media from 'constants/media.js';

export default {
  ContinueHolder: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 16,
    marginRight: 15,
    [media.TABLET]: {
      flexDirection: 'column',
    },
  },
  ContinueHolderSuccess: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 16,
    [media.TABLET]: {
      flexDirection: 'column',
    },
  },
  title: {
    ...gstyles.fonts.p4,
    fontWeight: 'normal',
    color: colors.primary.magenta,
  },
  pageText: {
    color: colors.secondary.darkGrey,
  },
};
