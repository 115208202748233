import React from 'react';
import PropTypes from 'prop-types';
import RestrictedButton from 'redux/modules/RemoteSession/v2/RestrictedButton';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ChevronLeftIcon } from '@utilitywarehouse/partner-ui-icons';
import { FeatureFlagsApi } from 'app/redux/modules/FeatureFlags/api';
import useFeature from 'app/lib/analytics/Optimizely/useFeature';
import { features, variations } from 'app/lib/analytics/Optimizely/features';
import { FLAGS } from 'app/redux/modules/FeatureFlags/constants';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    padding: '0 7px',
    minWidth: '0',
  },
}));

const NavigationButtonBack = ({ backLabel, backHandler }) => {
  const history = useHistory();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const classes = useStyles();

  const stickyNavFlagEnabled = useSelector(
    FeatureFlagsApi.getFlagSelector(FLAGS.CTA_STICKY_NAVIGATION_ENABLED)
  );
  const { variant: stickyNavVariant } = useFeature(features.STICKY_NAV);
  const stickyNavEnabled =
    stickyNavFlagEnabled ||
    stickyNavVariant === variations[features.STICKY_NAV].ON;

  const onClickHandler =
    typeof backHandler === 'function' ? backHandler : history.goBack;

  const backText =
    !isDesktop && stickyNavEnabled ? <ChevronLeftIcon /> : 'Back';

  const rootClassName =
    !isDesktop && stickyNavEnabled ? classes.buttonContainer : undefined;

  return (
    <RestrictedButton
      onClick={onClickHandler}
      label={backLabel ? backLabel : backText}
      variant="outlined"
      size="large"
      dataTestId="NavigationBackButton"
      classes={{
        root: rootClassName,
      }}
    />
  );
};

NavigationButtonBack.propTypes = {
  backHandler: PropTypes.func,
  backLabel: PropTypes.string,
};

export default NavigationButtonBack;
