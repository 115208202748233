import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { InputLabel, Typography } from '@material-ui/core';
import classnames from 'classnames';
import useMultiselectStyles from '../styles';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  radio: {
    color: theme.palette.grey[300],
  },
}));

const RadioButtonGroup = ({
  options,
  name,
  label,
  value,
  onChange,
  testId,
  radioGroupClasses = {},
  ...props
}) => {
  const classes = useStyles();
  const multiselectClasses = useMultiselectStyles();
  const isSelected = useCallback((option) => option === value, [value]);
  return (
    <div className={radioGroupClasses.container}>
      {label && (
        <InputLabel>
          <Typography className={multiselectClasses.label}>{label}</Typography>
        </InputLabel>
      )}
      <RadioGroup
        row
        name={name}
        className={classnames(
          radioGroupClasses.radioGroup,
          multiselectClasses.tileContainer
        )}
        value={value !== null && value !== undefined ? value : ''}
        onChange={onChange}
        data-test-id={testId}
        {...props}
      >
        {options &&
          options.map(({ value, label, testId }) => (
            <FormControlLabel
              key={name + String(value)}
              value={value}
              data-test-id={testId}
              classes={{
                root: classnames(
                  radioGroupClasses.radioButton,
                  multiselectClasses.tile,
                  isSelected(value) && multiselectClasses.tileChecked
                ),
              }}
              label={
                <Typography
                  className={classnames(
                    radioGroupClasses.optionLabel,
                    multiselectClasses.optionLabel,
                    isSelected(value) && multiselectClasses.optionLabelChecked
                  )}
                  component="span"
                >
                  {label}
                </Typography>
              }
              control={
                <Radio
                  classes={{ root: classes.radio }}
                  color={isSelected(value) ? 'secondary' : 'default'}
                />
              }
            />
          ))}
      </RadioGroup>
    </div>
  );
};

RadioButtonGroup.propTypes = {
  options: PropTypes.array,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  testId: PropTypes.any,
  radioGroupClasses: PropTypes.shape({
    container: PropTypes.string,
    radioGroup: PropTypes.string,
    radioButton: PropTypes.string,
    optionLabel: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
};

export default RadioButtonGroup;
