import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Fab,
  Box,
  Popper,
  IconButton,
  Grow,
  ClickAwayListener,
  useTheme,
} from '@material-ui/core';
import moment from 'moment';
import { CloseMediumIcon as CloseIcon } from '@utilitywarehouse/react-icons';
import { PhoneMediumIcon } from '@utilitywarehouse/react-icons';

import useStyles from './styles';

const HelpCTA = ({
  bankHolidays,
  getBankHolidays,
  enabled,
  calculateMargin,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  let visible = false;

  useEffect(() => {
    getBankHolidays();
    if (visible && enabled) {
      calculateMargin();
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'transition-popper' : undefined;

  const currentDate = moment();
  const openDate = currentDate.clone();
  const closeDate = currentDate.clone();
  let isBankHoliday = false;

  if (bankHolidays !== undefined && bankHolidays !== null) {
    isBankHoliday =
      bankHolidays.filter(
        (date) => date.get('date') === currentDate.format('YYYY-MM-DD')
      ).size > 0;
  }

  openDate.set('hour', 8).set('minute', 30);
  closeDate.set('hour', 17).set('minute', 30);

  if (currentDate.isoWeekday() === 7) {
    openDate.set('hour', 0).set('minute', 0);
    closeDate.set('hour', 0).set('minute', 0);
  } else if (isBankHoliday) {
    openDate.set('hour', 10).set('minute', 0);
    closeDate.set('hour', 16).set('minute', 0);
  } else if (currentDate.isoWeekday() === 6) {
    openDate.set('hour', 9).set('minute', 0);
    closeDate.set('hour', 16).set('minute', 30);
  }

  visible = currentDate.isBetween(openDate, closeDate);

  return (
    <>
      {visible && enabled && (
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Fab
              color="secondary"
              aria-describedby={id}
              onClick={handleClick}
              size="small"
              classes={{
                root: classes.phoneButton,
              }}
            >
              <PhoneMediumIcon />
            </Fab>
            <Popper
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClick={(e) => e.stopPropagation()}
              placement="top"
              transition
              modifiers={{
                flip: { enabled: false },
                offset: {
                  offset: `-105px, 0`,
                  enabled: true,
                },
              }}
            >
              {({ TransitionProps }) => (
                <Grow {...TransitionProps}>
                  <Box className={classes.box}>
                    <div className={classes.top}>
                      <Typography
                        component="h3"
                        variant="h4"
                        className={classes.heading}
                      >
                        Got a question?
                      </Typography>{' '}
                      <IconButton
                        className={classes.closeIcon}
                        aria-label="close"
                        onClick={() => setOpen(false)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                    <Typography variant="body1" component="p">
                      {' '}
                      Give our friendly team a call on{' '}
                      <a className={classes.telLink} href="tel:0333 777 0228">
                        0333 777 0228
                      </a>{' '}
                      and we’ll be happy to help. Our average call wait time is
                      under 3 minutes.
                    </Typography>{' '}
                    <svg
                      width="29"
                      height="15"
                      viewBox="0 0 29 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.6228 12.2725C12.8237 13.8326 15.1763 13.8326 16.3772 12.2725L29.0347 -4.17002C30.5533 -6.14272 29.147 -9 26.6575 -9H1.34251C-1.14701 -9 -2.5533 -6.14272 -1.03471 -4.17002L11.6228 12.2725Z"
                        fill={theme.palette.info.main}
                        stroke={theme.palette.info.main}
                        strokeWidth="2"
                      />
                      <clipPath>
                        <rect
                          width="29"
                          height="15"
                          fill="white"
                          transform="matrix(1 0 0 -1 0 15)"
                        />
                      </clipPath>
                    </svg>
                  </Box>
                </Grow>
              )}
            </Popper>
          </div>
        </ClickAwayListener>
      )}
    </>
  );
};

HelpCTA.propTypes = {
  bankHolidays: PropTypes.object,
  getBankHolidays: PropTypes.func.isRequired,
  enabled: PropTypes.bool.isRequired,
  calculateMargin: PropTypes.func.isRequired,
};

export default HelpCTA;
