import { makeStyles } from '@material-ui/core/styles';

export default (arrowOffset) =>
  makeStyles((theme) => ({
    container: {
      marginBottom: theme.spacing(2),
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    iconButton: {
      padding: 0,
      marginLeft: theme.spacing(2),
    },
    icon: {
      height: theme.spacing(3.5),
      width: theme.spacing(3.5),
    },
    infoContainer: {
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: `2px solid ${theme.palette.secondary.main}`,
      borderRadius: 15,
      padding: theme.spacing(1),
      marginTop: theme.spacing(3),
      maxWidth: 500,

      //Adding a triangle to the top of the info bubble using https://cssarrowplease.com/
      '&:before, &:after': {
        content: arrowOffset ? '""' : '',
        width: 0,
        height: 0,
        position: 'absolute',
        border: 'solid transparent',
        bottom: '100%',
        left: arrowOffset + 12,
      },

      '&:before': {
        borderBottomColor: theme.palette.secondary.main,
        borderWidth: 19,
        marginLeft: -19,
      },

      '&:after': {
        borderBottomColor: theme.palette.background.paper,
        borderWidth: 16,
        marginLeft: -16,
      },
    },
  }));
