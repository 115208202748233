import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography } from '@material-ui/core';
import { formatPrice } from 'redux/utils/formatter';
import Card from 'components/modules/Shared/Card/Card';
import SectionCentered from 'modules/layout/SectionCentered';

import useStyles from './styles';
import { price } from 'app/constants/propTypes';

const SummaryPanel = ({
  items,
  monthlyTotal,
  discountedMonthlyTotal,
  monthlyTotalExtraInfo,
  children,
}) => {
  const classes = useStyles();

  const renderPrice = ({ price, discountedPrice, limitedDiscountedPrice }) => {
    if (!discountedPrice && !limitedDiscountedPrice) {
      return (
        <Typography classes={{ root: classes.text }}>
          {formatPriceDescription(price)}
        </Typography>
      );
    }

    if (discountedPrice && !limitedDiscountedPrice) {
      return (
        <div className={classes.discountRow}>
          <Typography classes={{ root: classes.nonDiscountPrice }}>
            {formatPriceDescription(price)}
          </Typography>
          <Typography
            classes={{
              root: classNames(classes.discountPrice, classes.text),
            }}
          >
            {formatPriceDescription(discountedPrice)}
          </Typography>
        </div>
      );
    }

    return (
      <Typography
        classes={{
          root: classNames(classes.discountPrice, classes.text),
        }}
      >
        {formatPrice(limitedDiscountedPrice)}
      </Typography>
    );
  };

  return (
    <div className={classes.container}>
      <SectionCentered
        sectionCenteredClasses={{
          root: classes.sectionRoot,
          container: classes.sectionContainer,
        }}
      >
        <Card className={classes.card}>
          <div className={classes.cardSection}>
            <Typography variant="h2" color="primary">
              Summary
            </Typography>
          </div>
          {items.map(
            ({ id, name, price, discountedPrice, limitedDiscountedPrice }) => (
              <div className={classes.cardSection} key={id}>
                <Typography classes={{ root: classes.text }}>{name}</Typography>
                {renderPrice({
                  price,
                  discountedPrice,
                  limitedDiscountedPrice,
                })}
              </div>
            )
          )}
          <div className={classNames(classes.cardSection, classes.cardSummary)}>
            <Typography variant="h3">Monthly total</Typography>
            <div className={classes.textRight}>
              <Typography
                className={classNames(discountedMonthlyTotal && classes.green)}
                variant="h3"
              >
                {formatPrice(discountedMonthlyTotal ?? monthlyTotal)}
              </Typography>
              {monthlyTotalExtraInfo && (
                <Typography variant="body2">{monthlyTotalExtraInfo}</Typography>
              )}
            </div>
          </div>
          {children}
        </Card>
      </SectionCentered>
    </div>
  );
};

const formatPriceDescription = (price) => {
  if (price.value === 0) {
    return 'Free';
  }

  return formatPrice(price);
};

SummaryPanel.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      price: price.isRequired,
      discountPrice: price,
      limitedDiscountedPrice: price,
    })
  ).isRequired,
  monthlyTotal: price.isRequired,
  discountedMonthlyTotal: price.isRequired,
  monthlyTotalExtraInfo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  children: PropTypes.node,
};

export default SummaryPanel;
