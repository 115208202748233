import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    width: '100%',
  },
  circleButton: {
    border: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: 70,
    color: theme.palette.text.main,
    height: 40,
    width: 40,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  left: {
    marginRight: theme.spacing(2),
  },
  disabled: {
    border: `2px solid ${theme.palette.action.disabled}`,
  },
  indicatorContainer: {
    left: '50%',
    marginTop: '11px',
    padding: 0,
    position: 'absolute',
    transform: 'translateX(-50%)',
  },
  indicator: {
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'inline-block',
    height: 8,
    margin: theme.spacing(0, 0.5),
    width: 8,
  },
  indicatorSelected: {
    background: theme.palette.secondary.main,
    border: 'none',
  },
}));
