import { getBackgroundPaletteColor, getLinePaletteColor } from 'app/lib/theme';

export default (theme) => ({
  background: {
    backgroundColor: getBackgroundPaletteColor('default')(theme),
    borderRadius: '50%',
    bottom: '2.5%',
    height: '95%',
    left: '2.5%',
    maxWidth: '100%',
    position: 'absolute',
    right: '2.5%',
    top: '2.5%',
    width: '95%',
  },
  base: {
    position: 'relative',
    width: '100%',
  },
  image: {
    alignItems: 'center',
    bottom: '2.55%',
    display: 'flex',
    justifyContent: 'center',
    left: '2.5%',
    position: 'absolute',
    right: '2.5%',
    top: '2.5%',
    '& > *': {
      maxHeight: '100%',
      maxWidth: '100%',
    },
  },
  spotlight: {
    width: '100%',
    '& circle': {
      fill: 'none',
      r: 308,
      stroke: getLinePaletteColor('primary')(theme),
      strokeDasharray: 1935.2211032,
      strokeDashoffset: 1451.4158274,
      strokeLinecap: 'round',
      strokeWidth: 4,
    },
  },
  bottomLeft: {
    transform: 'rotate(90deg)',
  },
  bottomRight: {
    transform: 'rotate(0deg)',
  },
  topLeft: {
    transform: 'rotate(180deg)',
  },
  topRight: {
    transform: 'rotate(270deg)',
  },
});
