import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import { Typography, Paper } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import Label from 'components/modules/Shared/Label';
import { darkTheme, useStyles } from './styles';

/**
 *
 * @param {Object}  props
 * @param {boolean} props.isEligible   - boost eligibility, determines whether to show banner or not
 * @param {number}  props.serviceCount - Number of selected core services, determines copy and bonus amount
 * @param {Object}  props.classes      - class name overrides
 */

const BoostBanner = ({ isEligible, serviceCount, lead, classes = {} }) => {
  const internalClasses = useStyles();
  if (!isEligible) {
    return null;
  }

  const hasTwoServices = serviceCount >= 2;

  return (
    <div className={clsx(internalClasses.container, classes.container)}>
      <MuiThemeProvider theme={darkTheme}>
        {hasTwoServices && <Label position="right">£50 bonus unlocked</Label>}

        <div className={internalClasses.wrapper}>
          <Paper
            component="aside"
            className={internalClasses.root}
            classes={{ root: classes.root }}
          >
            <div className={internalClasses.imageWrapper}>
              <img
                src={'images/banners/boost_raf.svg'}
                alt="£50 boost banner"
                className={internalClasses.image}
              />
            </div>

            <div className={internalClasses.textContentWrapper}>
              <Typography
                component="p"
                variant="h3"
                className={internalClasses.textContent}
              >
                {hasTwoServices ? (
                  <>
                    You've qualified for a{' '}
                    <span className={internalClasses.highlight}>
                      £50 Referral Bonus
                    </span>
                  </>
                ) : (
                  <>
                    Add one more service to unlock a{' '}
                    <span className={internalClasses.highlight}>
                      £50 Referral Bonus
                    </span>{' '}
                    and £50 for{' '}
                    {lead?.leadProspect?.preferredFirstName ?? 'your friend'}
                  </>
                )}
              </Typography>
            </div>
          </Paper>
        </div>
      </MuiThemeProvider>
    </div>
  );
};

BoostBanner.propTypes = {
  isEligible: PropTypes.bool.isRequired,
  serviceCount: PropTypes.number.isRequired,
  lead: PropTypes.shape({
    leadProspect: PropTypes.shape({
      preferredFirstName: PropTypes.string,
    }),
  }),
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
};

export default BoostBanner;
