import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './styles';

const LoadingIndicator = ({
  loading,
  size = 42,
  thickness = 3.5,
  style,
  className,
}) => (
  <div style={{ ...styles.container, ...style }} className={className}>
    {loading && <CircularProgress size={size} thickness={thickness} />}
  </div>
);

LoadingIndicator.propTypes = {
  style: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  size: PropTypes.number,
  thickness: PropTypes.number,
  className: PropTypes.string,
};

export default LoadingIndicator;
