import { makeStyles } from '@material-ui/core/styles';

const style = (theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    height: 80,
    flexBasis: 'auto',
    padding: 0,
    fontSize: 18,
    color: theme.palette.primary.main,
    opacity: 1,
    [theme.breakpoints.up('sm')]: {
      fontSize: 20,
    },
  },
  indicator: {
    height: 4,
  },
  tabLabel: {
    display: 'inline-flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    gap: `${theme.spacing(1)}px`,
  },
  tabLabelIcon: {
    display: 'none',
    maxWidth: 48,
    maxHeight: 48,

    [theme.breakpoints.up('sm')]: {
      display: 'inline',
    },
  },
});

export default makeStyles(style);
