import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
const style = (theme) => ({
  root: {
    position: 'relative',
  },
  labelWrapper: {
    position: 'absolute',
    top: -15,
    right: theme.spacing(3),
    width: `calc(100% - ${theme.spacing(6)}px)`,
    display: 'flex',
    justifyContent: 'center',
  },
  labelPositionRight: {
    justifyContent: 'flex-end',
  },
  labelPositionLeft: {
    justifyContent: 'flex-start',
  },
  label: {
    display: 'inline-flex',
    alignItems: 'center',
    height: 30,
    padding: theme.spacing(1, 2),
    background: theme.palette.success.main,
    borderRadius: theme.spacing(0.5),
    color: theme.palette.common.white,
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
  },
});

const HighlightBar = ({
  label,
  enabled = true,
  component,
  position,
  children,
  classes = {},
}) => {
  const Component = component || 'div';
  const internalClasses = makeStyles(style, {
    name: 'HighlightBar',
  })();

  return (
    <Component className={clsx(internalClasses.root, classes.root)}>
      {children}
      {enabled && (
        <div
          className={clsx(internalClasses.labelWrapper, {
            [internalClasses.labelPositionRight]: position === 'right',
            [internalClasses.labelPositionLeft]: position === 'left',
          })}
        >
          <span className={internalClasses.label}>{label}</span>
        </div>
      )}
    </Component>
  );
};

HighlightBar.propTypes = {
  label: PropTypes.string.isRequired,
  enabled: PropTypes.bool,
  component: PropTypes.string,
  position: PropTypes.oneOf(['left', 'right', 'center']),
  children: PropTypes.node.isRequired,
  classes: PropTypes.objectOf(PropTypes.string),
};

export default React.memo(HighlightBar);
