import { makeStyles } from '@material-ui/core/styles';

const style = (theme) => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1, 0, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4, 0),
    },
  },
  containerLight: {
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  containerDarkAlt: {
    background: theme.palette.text.primary,
  },
  containerInner: {
    position: 'relative',
    display: 'grid',
    gridTemplateAreas: `
        "logo"
        "progress"
        "title"
      `,
    gap: `${theme.spacing(1)}px`,

    [theme.breakpoints.up('sm')]: {
      gridTemplateAreas: `
          "logo progress"
          "title progress"
        `,
      gridTemplateColumns: 'fit-content(100%) 1fr',
      gap: `${theme.spacing(2)}px`,
    },
  },
  headerLogo: {
    gridArea: 'logo',
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerTitle: {
    gridArea: 'title',
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'flex-start',
    rowGap: `${theme.spacing(2)}px`,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      columnGap: theme.spacing(2),
    },
  },
  headerProgress: {
    gridArea: 'progress',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'flex-end',
      justifyContent: 'center',
    },
  },
  title: {
    flexGrow: '1',
    color: theme.palette.common.white,
    fontSize: 36,
    [theme.breakpoints.up('sm')]: {
      fontSize: 42,
      lineHeight: '1',
    },
  },
  content: {
    paddingTop: theme.spacing(2),
    background: theme.palette.primary.main,
  },
});

export default makeStyles(style);
