import React from 'react';
import styles from './styles';
import Radium from 'radium';

class LineSeparator extends React.Component {
  static defaultProps = {
    type: 'solid',
  };

  render() {
    const { type } = this.props;

    return <div style={{ ...styles[type], ...(this.props.style || {}) }} />;
  }
}

export default Radium(LineSeparator);
