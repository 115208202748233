import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import moment from 'moment';
import TextField from 'modules/Shared/TextField';
import SelectField from 'modules/Shared/SelectField';
import styles from './styles';

const Months = [
  { text: 'Jan', val: 1 },
  { text: 'Feb', val: 2 },
  { text: 'Mar', val: 3 },
  { text: 'Apr', val: 4 },
  { text: 'May', val: 5 },
  { text: 'Jun', val: 6 },
  { text: 'Jul', val: 7 },
  { text: 'Aug', val: 8 },
  { text: 'Sep', val: 9 },
  { text: 'Oct', val: 10 },
  { text: 'Nov', val: 11 },
  { text: 'Dec', val: 12 },
];

class DateInput extends React.Component {
  constructor(props) {
    super(props);
    const { day, month, year, errorText } = this.props;
    this.state = {
      day: day || undefined,
      month: month || undefined,
      year: year || undefined,
      error: errorText || '',
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.day !== nextProps.day) {
      this.setState({ day: nextProps.day });
    }
    if (this.props.month !== nextProps.month) {
      this.setState({ month: nextProps.month });
    }
    if (this.props.year !== nextProps.year) {
      this.setState({ year: nextProps.year });
    }
    if (this.props.errorText !== nextProps.errorText) {
      this.setState({ error: nextProps.errorText });
    }
  }

  updateValue(field, value) {
    const { day, month, year } = this.state;
    const filteredValue =
      typeof value === 'string' ? Number(value.replace(/[^0-9]/g, '')) : value;
    const date = { day, month, year, [field]: filteredValue };
    this.setState({ [field]: filteredValue });
    this.updateParent(date);
  }

  updateParent({ day, month, year }) {
    const { onChange } = this.props;
    // validate the input in the background and send it back to parent if valid
    // but do not show the user the error msg because they are still typing
    // we only show it on blur
    const valid = this.validateInput({ day, month, year }, false);
    if (valid.isValid && month && typeof month === 'number' && year) {
      let formatted = '';
      if (day && month && year) {
        formatted = moment(`${day}-${month}-${year}`, 'DD-MM-YYYY').format();
      }
      if (!day && month && year) {
        formatted = moment(`${month}-${year}`, 'MM-YYYY').format();
      }
      onChange(formatted);
    }
  }

  validateInput({ day, month, year }, print = true) {
    const { validate } = this.props;
    let res = {
      isValid: true,
      day: true,
      year: true,
      msg: '',
    };
    if (day && (day < 1 || day > 31)) {
      res.day = false;
    }
    if (year && (year < 1901 || day > 2050)) {
      res.year = false;
    }
    if (res.day === false) {
      res.isValid = false;
      res.msg = 'Please enter a valid day';
    }
    if (res.year === false) {
      res.isValid = false;
      res.msg = 'Please enter a valid year';
    }
    if (res.day === false && res.year === false) {
      res.isValid = false;
      res.msg = 'Please enter a valid day and year';
    }
    if (res.isValid && month && year) {
      validate();
    }
    print && this.setState({ error: res.msg });
    return res;
  }

  render() {
    const { classes, dataTestId, errorText, required, withDays } = this.props;
    const { day, month, year, error } = this.state;
    const date = { day, month, year };
    const printError = errorText || error;

    return (
      <div className={classes.container}>
        <div className={classes.fieldsContainer} data-test-id={dataTestId}>
          {withDays && (
            <TextField
              tabIndex={0}
              label="Day"
              value={day}
              placeholder="1"
              onChange={(e, v) => this.updateValue('day', v)}
              onBlur={() => this.validateInput(date)}
              textFieldClasses={{
                input: classes.textInput,
                form: classes.spacing,
              }}
              maxLength="2"
              dataTestId={`${dataTestId}Day`}
              required={required}
              fullWidth={false}
            />
          )}
          <SelectField
            tabIndex={0}
            value={month}
            options={Months}
            id={`${dataTestId}Month`}
            testId="MonthDateInput"
            label="Month"
            onChange={(e) => this.updateValue('month', e.val)}
            onBlur={() => this.validateInput(date)}
            classNames={{
              root: classes.select,
            }}
            data-test-id={`${dataTestId}Month`}
            required={required}
          />
          <TextField
            tabIndex={0}
            value={year}
            label="Year"
            placeholder="1970"
            onChange={(e, v) => this.updateValue('year', v)}
            onBlur={() => this.validateInput(date)}
            textFieldClasses={{
              input: classes.textInput,
            }}
            maxLength="4"
            dataTestId={`${dataTestId}Year`}
            required={required}
            fullWidth={false}
          />
        </div>
        <Typography
          className={classes.error}
          data-test-id={`${dataTestId}Error`}
        >
          {printError}
        </Typography>
      </div>
    );
  }
}

DateInput.defaultProps = {
  classes: {},
  dataTestId: '',
  day: undefined,
  errorText: undefined,
  month: undefined,
  required: false,
  year: undefined,
  withDays: false,
};

DateInput.propTypes = {
  classes: PropTypes.object.isRequired,
  dataTestId: PropTypes.string,
  day: PropTypes.number,
  errorText: PropTypes.string,
  month: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  validate: PropTypes.func.isRequired,
  year: PropTypes.number,
  withDays: PropTypes.bool,
};

export default withStyles(styles)(DateInput);
