import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import MuiSnackbarContent from '@material-ui/core/SnackbarContent';

import useStyles from './styles';

function SnackbarContent({ classes = {}, variant, ...rest }) {
  const mergedClasses = {
    ...useStyles(),
    ...classes,
  };

  return (
    <MuiSnackbarContent
      {...rest}
      classes={{
        root: classnames(classes.rootDefault, {
          [mergedClasses.rootSuccess]: variant === 'success',
          [mergedClasses.rootError]: variant === 'error',
        }),
        message: classnames(
          mergedClasses.messageDefault,
          mergedClasses.message,
          variant !== 'default' && mergedClasses.messageVariant
        ),
      }}
    />
  );
}

SnackbarContent.defaultProps = {
  variant: 'default',
};

SnackbarContent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  variant: PropTypes.oneOf(['default', 'success', 'error']),
};

export default SnackbarContent;
