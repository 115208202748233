import React from 'react';
import PropTypes from 'prop-types';
import { Card as MuiCard } from '@material-ui/core';
import useStyles from './styles';

const Card = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <MuiCard classes={{ root: classes.root }} {...props}>
      {children}
    </MuiCard>
  );
};

Card.propTypes = {
  children: PropTypes.node,
};

export default Card;
