import { makeStyles } from '@material-ui/core/styles';
import { fade, darken } from '@material-ui/core/styles/colorManipulator';

const style = (theme) => ({
  labelLoading: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  colorError: {
    '&.MuiButton-contained': {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      '&:is(:hover)': {
        backgroundColor: fade(theme.palette.error.main, 0.65),
      },
      '&:where(:disabled)': {
        backgroundColor: fade(theme.palette.error.main, 0.25),
      },
    },
    '&.MuiButton-outlined, &.MuiButton-text': {
      color: theme.palette.error.main,
      borderColor: 'currentColor',
      // complex pseudo-classes with multiple attributes broken currently with jss
      // e.g. &:is(:foo, :bar) will render as
      // &:is(:foo, &:bar)
      //
      // https://github.com/cssinjs/jss/issues/1561
      '&:is(:hover)': {
        borderColor: darken(theme.palette.error.main, 0.5),
      },
      '&:is(:focus)': {
        borderColor: darken(theme.palette.error.main, 0.5),
      },
      '&:where(:disabled)': {
        color: fade(theme.palette.error.main, 0.5),
        borderColor: 'currentColor',
      },
    },
  },
  colorSuccess: {
    '&.MuiButton-contained': {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.common.white,
      '&:is(:hover)': {
        backgroundColor: fade(theme.palette.success.main, 0.65),
      },
      '&:where(:disabled)': {
        color: theme.palette.common.white,
        backgroundColor: fade(theme.palette.success.main, 0.25),
      },
    },
    '&.MuiButton-outlined, &.MuiButton-text': {
      color: theme.palette.success.main,
      borderColor: 'currentColor',
      '&:is(:hover)': {
        borderColor: darken(theme.palette.success.main, 0.5),
      },
      '&:is(:focus)': {
        borderColor: darken(theme.palette.success.main, 0.5),
      },
      '&:where(:disabled)': {
        color: fade(theme.palette.success.main, 0.5),
        borderColor: 'currentColor',
      },
    },
  },
});

export default makeStyles(style);
