import { makeStyles } from '@material-ui/core/styles';

const style = (theme) => ({
  item: {
    display: 'flex',
    alignItems: 'baseline',
    margin: theme.spacing(2, 0),
  },
  itemNumber: {
    minWidth: theme.spacing(4),
    height: theme.spacing(4),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    lineHeight: 1,
    fontSize: 16,
    fontWeight: 600,
    textAlign: 'center',
    color: theme.palette.common.white,
    borderRadius: theme.spacing(2),
  },
  itemNumberSmall: {
    fontSize: 14,
  },
  itemDescription: {
    marginLeft: theme.spacing(2),
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 600,
  },
  itemDescriptionSmall: {
    fontSize: theme.typography.subtitle1.fontSize,
  },
});

export default makeStyles(style);
