import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

const style = (theme) => ({
  wrapper: {
    display: 'inline-block',
    position: 'relative',
  },
  step: {
    fill: 'none',
    stroke: fade(theme.palette.primary.main, 0.2),
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    transformOrigin: '50% 50%',
  },
  stepDark: {
    stroke: fade(theme.palette.common.white, 0.4),
  },
  stepComplete: {
    stroke: theme.palette.services.broadband,
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    color: theme.palette.primary.main,
  },
  contentDark: {
    color: theme.palette.common.white,
  },
  contentComplete: {
    color: theme.palette.services.broadband,
  },
});

export default makeStyles(style);
