import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DropdownIcon } from '@utilitywarehouse/partner-ui-icons';

import useStyles from './styles';

const ContentDropdownIcon = ({ open }) => {
  const internalClasses = useStyles();

  return (
    <DropdownIcon
      className={classNames(internalClasses.icon, {
        [internalClasses.iconOpen]: open,
      })}
    />
  );
};

ContentDropdownIcon.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default ContentDropdownIcon;
