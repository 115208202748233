import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import { Link as MuiLink } from '@material-ui/core';
import useStyles from './styles';

const Link = ({ children, classes = {}, ...props }) => {
  const internalClasses = useStyles();
  return (
    <MuiLink className={clsx(internalClasses.root, classes.root)} {...props}>
      {children}
    </MuiLink>
  );
};

Link.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  children: PropTypes.node.isRequired,
};

export default Link;
