import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import map from 'lodash/map';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import useStyles from './styles';

const SIZE = {
  small: 'small',
  default: 'default',
};

const OrderedNumberListComponent = ({ data, size = SIZE.default }) => {
  const classes = useStyles();
  return map(data, (description, number) => (
    <ListItem key={number + 1} className={classes.item}>
      <span
        className={classNames(
          classes.itemNumber,
          size === SIZE.small && classes.itemNumberSmall
        )}
      >
        {number + 1}
      </span>
      <Typography
        color="primary"
        className={classNames(
          classes.itemDescription,
          size === SIZE.small && classes.itemDescriptionSmall
        )}
      >
        {description}
      </Typography>
    </ListItem>
  ));
};

OrderedNumberListComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
  size: PropTypes.oneOf([SIZE.small, SIZE.default]),
};

export default OrderedNumberListComponent;
