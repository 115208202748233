import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  sectionRoot: {
    maxWidth: 672,
  },
  sectionContainer: {
    padding: 0,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    '& > *:last-child': {
      marginBottom: 0,
    },
  },
  cardSection: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  cardSummary: {
    borderTop: `2px solid ${theme.palette.background.default}`,
    paddingTop: theme.spacing(2),
  },
  text: {
    fontSize: 20,
  },
  textRight: {
    textAlign: 'right',
  },
  discountRow: {
    display: 'flex',
    alignItems: 'baseline',
  },
  nonDiscountPrice: {
    fontSize: 16,
    textDecoration: 'line-through',
    textDecorationColor: theme.palette.success.main,
    textDecorationThickness: '2px',
  },
  discountPrice: {
    marginLeft: theme.spacing(0.5),
    color: theme.palette.success.main,
  },
  green: {
    color: theme.palette.success.main,
  },
}));
