import React from 'react';
import {
  Dialog as MuiDialog,
  DialogContent,
  IconButton,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Close } from '@material-ui/icons';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  closeContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
  },
  closeIcon: {
    height: 32,
    width: 32,
  },
  confettiBar: {
    width: '100%',
    background: 'url(images/bundlesPage/confetti.svg)',
    backgroundRepeat: 'repeat-x',
    backgroundPositionY: 'center',
    height: 40,
  },
}));

const Dialog = ({
  open,
  onClose,
  title,
  children,
  className,
  hasCloseIcon = true,
  confettiBar = false,
}) => {
  const classes = useStyles();

  return (
    <MuiDialog open={open} onClose={onClose} classes={{ paper: className }}>
      {confettiBar && <div className={classes.confettiBar} />}
      <DialogContent>
        <div className={classes.closeContainer}>
          <Typography variant="h3" color="primary">
            {title}
          </Typography>
          {hasCloseIcon && (
            <IconButton color="secondary" onClick={onClose}>
              <Close className={classes.closeIcon} />
            </IconButton>
          )}
        </div>
        {children}
      </DialogContent>
    </MuiDialog>
  );
};

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Dialog;
