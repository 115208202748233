import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const TabLabel = ({ label, icon }) => {
  const classes = useStyles();

  return (
    <div className={classes.tabLabel}>
      {icon && <img src={icon} alt="" className={classes.tabLabelIcon} />}
      {label}
    </div>
  );
};

TabLabel.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

export default TabLabel;
