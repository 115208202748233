import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, useMediaQuery } from '@material-ui/core';
import {
  useTheme,
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import { createTheme } from '@utilitywarehouse/partner-ui-mui-theme';
import SectionCentered from 'modules/layout/SectionCentered';
import { ApplicationApi } from 'redux/modules/App/api';
import { setStartAgainDialog } from 'redux/modules/App/actions';
import Profile from 'redux/modules/Profile/container';
import JumpTo from 'components/modules/Shared/JumpTo';
import Logo from './Logo';
import useStyles from './styles';

const darkTheme = createMuiTheme(createTheme('dark'));
const lightTheme = createMuiTheme(createTheme('light'));

const PageHeading = ({
  autoScroll = true,
  title,
  theme = 'dark',
  logoVariant = 'small',
  children,
}) => {
  const classes = useStyles();
  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.up('sm'));

  const dispatch = useDispatch();
  const isRemote = useSelector(ApplicationApi.isRemoteSession);

  return (
    <JumpTo id="page-header" autoScroll={autoScroll}>
      <MuiThemeProvider theme={theme === 'dark' ? darkTheme : lightTheme}>
        <header
          className={clsx(classes.container, {
            [classes.containerLight]: theme === 'light',
            [classes.containerDarkAlt]: theme === 'darkAlternate',
          })}
        >
          <SectionCentered
            sectionCenteredClasses={{
              container: clsx(classes.containerInner),
            }}
          >
            <div className={classes.headerLogo}>
              <Logo
                onClick={() => {
                  !isRemote && dispatch(setStartAgainDialog(true));
                }}
                disabled={isRemote}
                theme={theme}
                variant={logoVariant}
              />
              {!isMobile && <Profile />}
            </div>
            <div className={classes.headerTitle}>
              {title && (
                <Typography className={classes.title} variant="h1">
                  {title}
                </Typography>
              )}
            </div>
            <div className={classes.headerProgress}>
              {isMobile && <Profile />}
            </div>
          </SectionCentered>
        </header>
        {children && (
          <SectionCentered sectionCenteredClasses={{ root: classes.content }}>
            {children}
          </SectionCentered>
        )}
      </MuiThemeProvider>
    </JumpTo>
  );
};

PageHeading.propTypes = {
  autoScroll: PropTypes.bool,
  title: PropTypes.string,
  theme: PropTypes.oneOf(['dark', 'darkAlternate', 'light']),
  logoVariant: PropTypes.oneOf(['small', 'large']),
  children: PropTypes.node,
};

export default PageHeading;
