import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    textAlign: 'center',
  },
  posRight: {
    justifyContent: 'right',
    marginRight: theme.spacing(2),
  },
  posLeft: {
    justifyContent: 'left',
    marginLeft: theme.spacing(2),
  },
  text: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    justifyContent: 'center',
    fontWeight: theme.typography.fontWeightMedium,
    padding: theme.spacing(0.5, 2, 0.5, 2),
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
    width: 'fit-content',
    lineHeight: 1.2,
  },
}));

const Label = ({ children, position = 'right', classes = {} }) => {
  const internalClasses = useStyles();
  return (
    <div
      className={clsx(internalClasses.root, classes.root, {
        [internalClasses.posRight]: position === 'right',
        [internalClasses.posLeft]: position === 'left',
      })}
    >
      <Typography
        classes={{
          root: clsx(internalClasses.text, classes.root, classes.text),
        }}
      >
        {children}
      </Typography>
    </div>
  );
};

Label.propTypes = {
  children: PropTypes.node.isRequired,
  position: PropTypes.oneOf(['right', 'left']),
  classes: PropTypes.objectOf(PropTypes.string),
};

export default Label;
