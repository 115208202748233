import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

const cellHeight = 80;
const style = ({ breakpoints, palette, spacing, typography }) => ({
  container: {
    display: 'flex',
    marginTop: spacing(2),
    textAlign: 'center',
    overflowX: 'auto',
    [breakpoints.up('sm')]: {
      justifyContent: 'center',
    },
  },
  legendContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: cellHeight,
    '& > *': {
      borderLeft: `2px solid ${palette.primary.main}`,
    },
    '& > *:first-child': {
      borderTop: `2px solid ${palette.primary.main}`,
      borderTopLeftRadius: '16px',
    },
    '& > *:last-child': {
      borderBottomLeftRadius: '16px',
      borderBottom: `2px solid ${palette.primary.main}`,
      flexGrow: 1,
    },
  },
  legendCell: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    justifyContent: 'flex-start',

    maxWidth: 156,
    height: cellHeight,
    padding: spacing(1),
    backgroundColor: palette.common.white,
    fontWeight: typography.fontWeightMedium,
    textAlign: 'right',
    [breakpoints.up('sm')]: {
      width: 156,
      padding: spacing(3, 2, 2, 1),
    },

    '&:nth-of-type(even)': {
      backgroundColor: palette.background.default,
    },
  },
  columnContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(184px, max-content))',
    gridAutoFlow: 'column',
    maxWidth: '100%',
    '& > *:first-child': {
      '& > *:first-child': {
        borderTopLeftRadius: '16px',
      },
    },
    '& > *:not(:first-child)': {
      '&:after': {
        backgroundColor: 'rgba(255,255,255, 0.8)',
        content: "''",
        height: cellHeight + 2, // cell + border
        left: 0,
        position: 'absolute',
        top: 0,
        width: 2,
      },
    },
    '& > *:last-child': {
      '& > *': {
        borderRight: `2px solid ${palette.primary.main}`,
      },
      '& > *:first-child': {
        borderTopRightRadius: '16px',
      },
      '& > *:last-child': {
        borderBottomRightRadius: '16px',
      },
    },
  },
  column: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    '& > *:first-child': {
      background: palette.primary.main,
      color: palette.common.white,
      fontFamily: `Aeonik,${typography.fontFamily}`,
      fontSize: 20,
      fontWeight: typography.fontWeightMedium,
      whiteSpace: 'nowrap',
    },
    '& > *:nth-child(2)': {
      borderTop: `2px solid ${palette.primary.main}`,
    },
    '& > *:last-child': {
      borderBottom: `2px solid ${palette.primary.main}`,
      flexGrow: 1,
    },
    '&:before': {
      backgroundColor: fade(palette.primary.main, 0.2),
      bottom: 2,
      content: "''",
      height: 'calc(100% - 4px)',
      position: 'absolute',
      left: 0,
      width: 2,
    },
    '&:first-of-type:before': {
      height: 'calc(100% - 4px - 75px)',
    },
  },
  cell: {
    backgroundColor: palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    height: cellHeight,
    justifyContent: 'center',
    padding: spacing(2),

    '&:nth-of-type(odd):not(:first-of-type)': {
      backgroundColor: palette.background.default,
    },
    '&$fluid': {
      height: 'auto',
      minHeight: cellHeight,
    },
  },
  fluid: {},
});

export default makeStyles(style);
