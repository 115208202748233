import React from 'react';
import PropTypes from 'prop-types';

import RestrictedButton from 'redux/modules/RemoteSession/v2/RestrictedButton';
import { useHistory } from 'react-router';

const NavigationButtonNext = ({
  next,
  dataTestId,
  clickHandler,
  disabled,
  label,
  classNames,
  loading = false,
  type = 'button',
}) => {
  const history = useHistory();
  const defaultHandler = next ? () => history.push(next) : () => {};

  return (
    <RestrictedButton
      dataTestId={dataTestId}
      onClick={clickHandler ? clickHandler : defaultHandler}
      disabled={disabled || false}
      label={label || 'Next'}
      variant="contained"
      className={classNames}
      loading={loading}
      size="large"
      type={type}
    />
  );
};

NavigationButtonNext.defaultProps = {
  dataTestId: 'NavigationNextButton',
  disabled: false,
  label: 'Next',
};

NavigationButtonNext.propTypes = {
  dataTestId: PropTypes.string,
  clickHandler: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  next: PropTypes.string,
  classNames: PropTypes.object,
  loading: PropTypes.bool,
  type: PropTypes.string,
};

export default NavigationButtonNext;
