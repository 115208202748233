import { makeStyles } from '@material-ui/core/styles';

const style = (theme) => ({
  rootDefault: {
    minWidth: 'unset !important',
  },
  rootSuccess: {
    backgroundColor: theme.palette.success.main,
  },
  rootError: {
    backgroundColor: theme.palette.error.main,
  },
  messageDefault: {
    ...theme.typography.subtitle2,
  },
  messageVariant: {
    color: theme.palette.common.white,
  },
});

export default makeStyles(style);
