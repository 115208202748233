import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, CardContent } from '@material-ui/core';
import { CheckCard } from '@utilitywarehouse/partner-ui-mui-components';

const useStyles = makeStyles((theme) => ({
  btnContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
  },
  checkCardBtnBase: {
    width: '100%',
  },
  checkCardRoot: {
    width: '49%',
  },
}));

export const ContentListTab = ({ value, label, selected, onChange }) => {
  const classes = useStyles();
  return (
    <CheckCard
      checkCardClasses={{
        root: classes.checkCardRoot,
        buttonBase: classes.checkCardBtnBase,
      }}
      checked={selected}
      name={label}
      onChange={() => onChange(value)}
      value={value}
      variant="radio"
    >
      <CardContent classes={{ root: classes.contentCard }}>
        <Typography color="primary" variant="h4">
          {label}
        </Typography>
      </CardContent>
    </CheckCard>
  );
};

ContentListTab.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool,
};

export const ContentListTabs = ({ selected, onChange, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.btnContainer}>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          selected: child.props.value === selected,
          onChange,
        })
      )}
    </div>
  );
};

ContentListTabs.propTypes = {
  children: PropTypes.node.isRequired,
  selected: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
