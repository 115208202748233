import { makeStyles } from '@material-ui/core/styles';

const style = (theme) => ({
  phoneButton: {
    position: 'absolute',
    top: '-60px',
    right: theme.spacing(0),
  },
  box: {
    maxWidth: '252px',
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
    position: 'relative',
    '& > svg': {
      position: 'absolute',
      right: '8px',
      bottom: '-15px',
    },
  },

  top: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1.5),
    alignItems: 'center',
  },

  heading: {
    fontSize: theme.spacing(2),
  },

  closeIcon: {
    color: theme.palette.secondary.main,
    padding: 0,
    fontSize: '3rem',
    width: 32,
    height: 32,
  },
  telLink: {
    color: theme.palette.secondary.main,
    fontWeight: 600,
    textDecoration: 'none',
  },
});
export default makeStyles(style);
