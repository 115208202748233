import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import { CardHeader as MuiCardHeader } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  content: {
    fontSize: theme.typography.h4.fontSize,
    textAlign: 'center',
  },
  contentLeft: {
    textAlign: 'left',
  },
}));

const CardHeader = ({ classes = {}, alignLeft = false, ...props }) => {
  const internalClasses = styles();
  return (
    <MuiCardHeader
      classes={{
        root: clsx(internalClasses.root, classes.root),
        title: clsx(internalClasses.content, {
          [internalClasses.contentLeft]: alignLeft,
        }),
      }}
      {...props}
    />
  );
};

CardHeader.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  alignLeft: PropTypes.bool,
};

export default CardHeader;
