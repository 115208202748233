import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

class CopyButton extends Component {
  static async checkClipboardPermissions() {
    const { state } = await navigator.permissions.query({
      name: 'clipboard-write',
    });
    return state === 'granted' || state === 'prompt';
  }

  constructor() {
    super();
    this.clipboard = !!navigator.clipboard;
    this.input;
    this.button;

    this.onCopy = this.onCopy.bind(this);
    this.state = {
      clipboardPermission: false,
    };
  }

  async componentDidMount() {
    if (!this.clipboard) {
      this.button.addEventListener('click', this.onCopy, true);
    }

    if (this.clipboard) {
      try {
        const clipboardPermission = await CopyButton.checkClipboardPermissions();
        this.setState({
          clipboardPermission,
        });
      } catch (err) {
        this.setState({
          clipboardPermission: true,
        });
      }
    }
  }

  componentWillUnmount() {
    if (!this.clipboard) {
      this.button.removeEventListener('click', this.onCopy);
    }
  }

  async onCopy() {
    const { value, onCopy } = this.props;
    const { clipboardPermission } = this.state;

    if (navigator.share) {
      let url = /^https?:\/\//.test(value) ? value : undefined;
      try {
        await window.navigator.share({
          title: 'Invitation to remote appointment',
          text: !url ? value : 'Join a remote appointment',
          url,
        });
      } catch (err) {
        // Don't throw on abort, i.e. when user dismisses the
        // device share pop-up
        if (err.name === 'AbortError') {
          return false;
        }
      }
    } else {
      if (this.clipboard && clipboardPermission) {
        await navigator.clipboard.writeText(value);
      } else {
        this.input.select();
        document.execCommand('copy');
      }
    }

    onCopy(value);
  }

  render() {
    const {
      value,
      label,
      buttonClasses,
      buttonProps: buttonOverrides,
    } = this.props;

    const { clipboardPermission } = this.state;

    let buttonProps = {
      variant: 'contained',
      classes: buttonClasses,
      ...buttonOverrides,
    };

    if (this.clipboard) {
      buttonProps = {
        ...buttonProps,
        onClick: this.onCopy,
      };
    } else {
      buttonProps = {
        ...buttonProps,
        ref: (e) => {
          this.button = e;
        },
      };
    }

    return (
      <>
        {(!this.clipboard || !clipboardPermission) && (
          <input
            type="text"
            value={value}
            readOnly
            ref={(e) => {
              this.input = e;
            }}
            style={{ position: 'absolute', left: -9000 }}
          />
        )}
        <Button {...buttonProps}>{label}</Button>
      </>
    );
  }
}

CopyButton.defaultProps = {
  label: 'Copy',
  onCopy: () => {},
  buttonClasses: {},
  buttonProps: {},
};

CopyButton.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  onCopy: PropTypes.func,
  buttonClasses: PropTypes.objectOf(PropTypes.string),
  buttonProps: PropTypes.objectOf(PropTypes.any),
};

export default CopyButton;
