import { makeStyles } from '@material-ui/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

const style = (theme) => ({
  root: {
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
    color: theme.palette.text.primary,
    '&:hover': {
      textDecoration: 'none',
      color: fade(theme.palette.text.primary, 0.65),
    },
  },
});

export default makeStyles(style);
