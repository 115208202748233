import { connect } from 'react-redux';
import { ApplicationApi } from 'redux/modules/App/api';
import { EntryFormApi } from 'app/redux/modules/EntryForm/api';
import { getBankHolidays } from 'redux/modules/App/actions';
import { FLAGS } from 'redux/modules/FeatureFlags/constants';
import { FeatureFlagsApi } from 'app/redux/modules/FeatureFlags/api';

import HelpCTA from './HelpCTA';

const mapStateToProps = (state) => {
  const tenantInboundCallEnabled = FeatureFlagsApi.getFlag(
    state,
    FLAGS.ORGANIC_TENANT_INBOUND_CALL_ENABLED
  );

  return {
    bankHolidays: ApplicationApi.getBankHolidays(state),
    enabled:
      ApplicationApi.isOrganicApplication(state) &&
      (tenantInboundCallEnabled || EntryFormApi.isHomeOwner(state)),
  };
};

export default connect(mapStateToProps, {
  getBankHolidays,
})(HelpCTA);
