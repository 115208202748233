import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import MUIButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from './styles';

const Button = ({
  color = 'secondary',
  classes = {},
  loading = false,
  children,
  ...props
}) => {
  const internalClasses = useStyles();

  // get the label size, or closest approximation if it's a mix of strings and elements,
  // so that the size of the button in a loading state closely matches that of the
  // static button. It's to prevent layout shifts as much as possible.
  // A character length can then be used to set the width, using ch units
  let labelSize = 0;

  if (Array.isArray(children)) {
    // filter out non-string child nodes, and calculate a length
    labelSize =
      children
        .filter((c) => typeof c === 'string')
        .reduce((acc, v) => acc + v, '')
        .replace(/\s/g, '')?.length ?? 0;
  } else if (typeof children === 'string') {
    labelSize = children.replace(/\s/g, '').length;
  }

  return (
    <MUIButton
      classes={{
        root: classnames(internalClasses.root, classes.root, {
          [internalClasses.colorError]: color === 'error',
          [internalClasses.colorSuccess]: color === 'success',
        }),
      }}
      color={color !== 'error' && color !== 'success' ? color : 'secondary'}
      {...props}
    >
      {!loading && children}
      {loading && (
        <span
          className={internalClasses.labelLoading}
          style={{ minWidth: `${labelSize}ch` }}
        >
          <CircularProgress size={24} thickness={5} />
        </span>
      )}
    </MUIButton>
  );
};

Button.propTypes = {
  color: PropTypes.oneOf([
    'default',
    'inherit',
    'primary',
    'secondary',
    'error',
    'success',
  ]),
  loading: PropTypes.bool,
  children: PropTypes.node,
  classes: PropTypes.objectOf(PropTypes.string),
};

export default Button;
