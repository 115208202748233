import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Popper, Grow, ClickAwayListener, useTheme } from '@material-ui/core';

const DropdownMenu = ({ open, onClose, children = [], classes = {} }) => {
  const anchorRef = useRef();
  const theme = useTheme();
  if (children.length < 2) {
    throw new TypeError(
      `Two child components required (anchor + menu content), received ${children.length}`
    );
  }
  const anchor = children[0];
  const content = children[1];

  return (
    <>
      {React.cloneElement(anchor, {
        ref: anchorRef,
      })}
      <Popper
        open={open}
        anchorEl={anchorRef?.current}
        transition
        modifiers={{
          offset: {
            offset: `0, ${theme.spacing(1)}`,
            enabled: true,
          },
        }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <div className={classes.dropdown}>
              <ClickAwayListener onClickAway={onClose}>
                {content}
              </ClickAwayListener>
            </div>
          </Grow>
        )}
      </Popper>
    </>
  );
};

DropdownMenu.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  classes: PropTypes.exact({
    dropdown: PropTypes.string,
  }),
};

export default DropdownMenu;
