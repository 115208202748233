import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import { Typography } from '@material-ui/core';

import ProgressTracker from './ProgressTracker';

import { makeStyles } from '@material-ui/core/styles';

const style = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(2),
  },
  bold: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  label: {
    '& + &': {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(1),
    },
  },
});

const useStyles = makeStyles(style);

const SectionTracker = ({ current, next, sections, active, classes = {} }) => {
  const internalClasses = useStyles();

  return (
    <div className={clsx(internalClasses.root, classes.root)}>
      <ProgressTracker
        steps={sections}
        currentStep={active}
        active
        Icon={
          <Typography variant="body2" className={internalClasses.bold}>
            {active} of {sections}
          </Typography>
        }
      />
      <div className={internalClasses.labels}>
        <Typography
          variant="body2"
          className={clsx(internalClasses.label, internalClasses.bold)}
        >
          {current}
        </Typography>
        {next && (
          <Typography variant="body2" className={internalClasses.label}>
            Up next: {next}
          </Typography>
        )}
      </div>
    </div>
  );
};

SectionTracker.propTypes = {
  current: PropTypes.string.isRequired,
  next: PropTypes.string,
  sections: PropTypes.number.isRequired,
  active: PropTypes.number.isRequired,
  classes: PropTypes.objectOf(PropTypes.string),
};
export default SectionTracker;
