import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { compose } from 'lodash/fp';
import { Tooltip } from '@material-ui/core';
import { withTheme, withStyles } from '@material-ui/core/styles';

import {
  BackButtonNavigationContainer,
  SaveButtonNavigationContainer,
} from 'redux/modules/Navigation/container';
import useFeature from 'app/lib/analytics/Optimizely/useFeature';
import { features, variations } from 'app/lib/analytics/Optimizely/features';
import SectionCentered from 'modules/layout/SectionCentered';
import BackButton from 'components/modules/Shared/NavigationButton/Back';
import HelpCTA from 'components/modules/Shared/HelpCTA';
import NavigationButtonNext from 'components/modules/Shared/NavigationButton/Next';
import Hidden from '@material-ui/core/Hidden';

import { FeatureFlagsApi } from 'app/redux/modules/FeatureFlags/api';
import { FLAGS } from 'app/redux/modules/FeatureFlags/constants';

import styles, { useNextButtonStyles } from './styles';

const RenderBackPart = ({ back, ...props }) => {
  if (back) {
    if (typeof back === 'boolean') {
      return <BackButtonNavigationContainer {...props} />;
    }

    if (typeof back === 'function') {
      return <BackButton backHandler={back} {...props} />;
    }
  }

  // NOTE: to prevent misalignment of the next button when back is disabled
  return (
    <Hidden xsDown>
      <div />
    </Hidden>
  );
};

RenderBackPart.propTypes = {
  back: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

const RenderNextPart = ({
  next,
  nextTarget,
  nextLabel,
  nextHandler,
  nextDisabled,
  nextType = 'button',
  nextdataTestId,
  nextHelperText,
  ...props
}) => {
  const classes = useNextButtonStyles();

  if (!next) {
    return <Fragment />;
  }

  const nextProps = {
    next: nextTarget,
    label: nextLabel,
    clickHandler: nextHandler,
    disabled: nextDisabled,
    dataTestId: nextdataTestId,
    type: nextType,
    ...props,
  };

  if (nextHelperText) {
    return (
      <Tooltip
        classes={classes}
        title={nextHelperText}
        placement="top-start"
        arrow
      >
        <span>
          <NavigationButtonNext {...nextProps} />
        </span>
      </Tooltip>
    );
  }

  return <NavigationButtonNext {...nextProps} />;
};

RenderNextPart.propTypes = {
  next: PropTypes.bool,
  nextTarget: PropTypes.string,
  nextLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  nextHandler: PropTypes.func,
  nextDisabled: PropTypes.bool,
  nextType: PropTypes.string,
  nextdataTestId: PropTypes.string,
  nextHelperText: PropTypes.string,
};

const NavigationPane = ({
  classes,
  theme,
  marginTopZero,
  topColor,
  bottomColor,
  children,
  helpCtaEnabled,
  ...props
}) => {
  const stickyNavFlagEnabled = useSelector(
    FeatureFlagsApi.getFlagSelector(FLAGS.CTA_STICKY_NAVIGATION_ENABLED)
  );
  const { variant: stickyNavVariant } = useFeature(features.STICKY_NAV);
  const stickyNavEnabled =
    stickyNavFlagEnabled ||
    stickyNavVariant === variations[features.STICKY_NAV].ON;

  const [extraMargin, setExtraMargin] = useState(false);

  function calculateMargin() {
    setExtraMargin(true);
  }

  return (
    <>
      <SectionCentered
        sectionCenteredClasses={{
          root: classNames(classes.whiteSectionCentered, {
            [classes.sticky]: stickyNavEnabled,
            [classes.marginTop]: extraMargin,
            [classes.marginTopZero]: marginTopZero,
          }),
        }}
      >
        <div
          className={
            stickyNavEnabled
              ? classes.buttonContainerStickyNav
              : classes.buttonContainer
          }
        >
          <RenderBackPart classes={classes} {...props} />
          {helpCtaEnabled && <HelpCTA calculateMargin={calculateMargin} />}
          <div
            className={
              stickyNavEnabled
                ? classes.nextButtonContainerStickyNav
                : classes.nextButtonContainer
            }
          >
            <SaveButtonNavigationContainer />
            {children}
            <RenderNextPart classes={classes} {...props} />
          </div>
        </div>
      </SectionCentered>
    </>
  );
};

NavigationPane.defaultProps = {
  nextdataTestId: 'NavigationNextButton',
  topColor: null,
  children: null,
  nextHelperText: null,
};

NavigationPane.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  topColor: PropTypes.string,
  bottomColor: PropTypes.string,
  children: PropTypes.node,
  helpCtaEnabled: PropTypes.bool,
  marginTopZero: PropTypes.bool,
};

export default compose(withStyles(styles), withTheme)(NavigationPane);
