import { getErrorPaletteColor, getSpacing } from 'app/lib/theme';

export default (theme) => {
  const errorColor = getErrorPaletteColor('main')(theme);
  const spacing = getSpacing(theme);

  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: 340,
    },
    fieldsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      width: 360,
    },
    textInput: {
      width: 100,
    },
    select: {
      fontSize: '1rem',
      marginRight: spacing(2.5),
      width: 100,
    },
    error: {
      color: errorColor,
    },
    spacing: {
      marginRight: spacing(2.5),
    },
  };
};
