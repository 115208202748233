import { fade, lighten } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/core/styles';

const style = (theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: theme.spacing(0.25, 0.25, 0.25, 2),
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(2),
    background: fade(theme.palette.primary.main, 0.15),
  },
  labelWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
  },
  label: {
    fontSize: 20,
    fontWeight: 600,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
  },
  button: {
    flexShrink: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: `2px ${theme.spacing(2) - 2}px ${theme.spacing(2) - 2}px 2px`,
    height: theme.spacing(6),

    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.4),
    },
  },
});

export default makeStyles(style);
